<template>
  <v-card
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="35rem"
  >
    <v-card-text>
      <Logo class="mx-auto" :logo="workspaceLogo" :white="false" smaller />
    </v-card-text>

    <div class="mb-5">
      <IcoContractRejected type="success" />
    </div>

    <v-card-title class="px-8 px-md-15">
      <h1 class="headline mb-2 mx-auto px-md-16 font-weight-black secondary--text">
        {{ $t('general.sent') }}
      </h1>
    </v-card-title>

    <v-card-text class="py-0 px-5 px-md-15">
      <p class="px-5 px-md-15 font-weight-medium secondary--text">
        {{ $t('aml.sent') }}
      </p>
    </v-card-text>

    <v-card-actions v-if="canVisitSigni" class="px-10 mt-15 mb-0">
      <div class="mx-auto">
        <div class="subtitle-2 secondary--text">
          {{ $t('general.question.like_it') }}
        </div>

        <v-btn text class="underlined" x-large @click="openUrl($t('links.aml.how_work'))">
          {{ $t('general.signi_more') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { goToLinkWithTarget } from '@/common/reusable/routing';
import environment from '@/config/environment';
import IcoContractRejected from '@/components/svg/IcoContractRejected';

export default {
  name: 'Done',
  components: {
    IcoContractRejected,
  },
  computed: {
    ...mapGetters({
      workspaceLogo: 'auths/workspaceLogo',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    canVisitSigni() {
      return !environment.isRestrictedMode();
    },
  },
  methods: {
    openUrl(url) {
      goToLinkWithTarget(url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
